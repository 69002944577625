import { GlobalStyle } from 'ui/settings/global'
import { Text } from '../../ui/atoms'
import { LayoutMobile } from '../../ui/layout'
import { config } from 'config'
import { headingTextProps } from './headingTextProps'
import { contentTextProps } from './contentTextProps'

export const Rules = () => (
  <GlobalStyle>
    <LayoutMobile
      {...config.backgrounds.RULES_BACKGROUND}
      h="auto"
      isRulesPage={true}
    >
      <LayoutMobile.Content>
        <Text
          {...headingTextProps}
          as="h1"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          ПРАВИЛА СТИМУЛИРУЮЩЕЙ АКЦИИ «ОСЕНЬ ПОДАРКОВ ОТ KD PAY И МТС»
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          Стимулирующая акция под названием «Осень подарков от KD Pay и МТС»
          (далее по тексту - Акция) проводится согласно изложенным ниже условиям
          (далее по тексту – Правила) в рамках рекламной кампании. Данная Акция
          не является лотереей либо иной игрой, основанной на риске, не требует
          внесения платы за участие. Процедура проведения Акции не связана с
          внесением Участниками платы за принятие в ней участия, призовой фонд
          Акции сформирован за счет средств Организатора акции.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          1.ОБЩИЕ ПОЛОЖЕНИЯ ПРОВЕДЕНИЯ СТИМУЛИРУЮЩЕЙ АКЦИИ «ОСЕНЬ ПОДАРКОВ ОТ KD
          PAY И МТС»
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.1. Наименование Акции: «Осень подарков от KD Pay и МТС».
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.2. Организатор Акции: Общество с ограниченной ответственностью
          «РИТЕЙЛ-ПРОЦЕССИНГ» (далее по тексту настоящих Правил -
          «Организатор»).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.3. Территория проведения Акции - Россия, Калининградская область.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.4. Наименование Организатора. <br />
          Организатором Акции, то есть юридическим лицом, созданным в
          соответствии с законодательством Российской Федерации, организующим
          проведение Акции непосредственно и/или через Оператора, является
          Общество с ограниченной ответственностью «РИТЕЙЛ-ПРОЦЕССИНГ»: <br />
          - местонахождение: 236023, Калининградская обл., г. Калининград,
          Советский проспект, зд. 234, помещение 4; <br />
          - ОГРН 1213900003492, ИНН 3906400490. <br />
          Источник информации об организаторе Акции, о правилах ее проведения,
          количество призов по результатам Акции, сроках, месте и порядке их
          получения – подраздел «Осень подарков» в разделе «Сервисы» в мобильном
          приложении «Клуб Друзей» (далее – «Раздел «Осень подарков»»).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.5. Генеральными партнёрами Акции являются: ООО «Евроритейл», ООО
          «Народный магазин», ООО «Тетрис-Калининград», ООО «Робот Икс» и ПАО
          МТС (далее – «Партнёры»).
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.6. Участники Акции: покупатели магазинов “SPAR”, “EUROSPAR”,
          “INTERSPAR”, “SPAR Express”, “SPAR Gourmet”, «Семья» и «Победа», а
          также интернет-магазинов “SPAR Online” и “SPAR39минут”, достигшие
          возраста 18 лет, в городах и населенных пунктах Калининградской
          области, кроме сотрудников ООО «РИТЕЙЛ-ПРОЦЕССИНГ», ООО «Евроритейл»,
          ООО «Тетрис-Калининград», ООО «Народный магазин» и ООО «Робот Икс и
          компаний, принимающих непосредственное участие в процессе розыгрыша
          призов по данной Акции.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          2. СРОКИ ПРОВЕДЕНИЯ АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          2.1. Общий срок проведения Акции: <br />
          Акция проводится в период с 14 октября 2024 г. по 22 ноября 2024 года
          (включительно), включая срок выдачи Призов Победителям Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          2.2. Выдача электронных монет для получения призов Акции, в том числе
          электронных золотых билетов для участия в розыгрыше Призов первого
          уровня, происходит ежедневно с 10 часов 00 минут 00 секунд 14 октября
          2024 года до 23 часов 59 минут 59 секунд 17 ноября 2024 года
          (включительно) по местному времени в подразделе «Осень сокровищ» в
          разделе «Сервисы» в мобильном приложении Клуба Друзей за покупки в
          магазинах “SPAR”, “EUROSPAR”, “INTERSPAR”, “SPAR Express”, “SPAR
          Gourmet”, «Семья» и «Победа» Калининградской области, а также
          интернет-магазинов “SPAR Online” и “SPAR39минут” с использованием
          сервиса “KD Pay” в качестве платёжного метода (далее – «Покупка с KD
          Pay»).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          2.3. Выдача Призов первого уровня происходит с 24 октября 2024 года по
          22 ноября 2024 года по рабочим дням в период времени с 13 часов 00
          минут 00 секунд до 18 часов 00 минут 00 секунд по адресу: г.
          Калининград, Советский проспект, зд. 234, помещение 4. Конкретное
          время выдачи приза заранее согласовывается с каждым Победителем Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          2.4. Выдача прочих Призов происходит с 14 октября 2024 года по 17
          ноября 2024 года круглосуточно в разделе «Осень подарков».
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          3. ПРИЗОВОЙ ФОНД АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.1. Призовой фонд Акции формируется за счет средств Организатора
          Акции и Партнёров Акции. Каждый Приз первого уровня включает в себя
          выигрыш в натуральном выражении (материальную часть приза) и условную
          денежную часть, предусмотренную для уплаты налога на доход физического
          лица, являющегося Победителем Акции. Организатор Акции является
          налоговым агентом для уплаты такого налога за Победителей Акции. Призы
          второго уровня представляют собой дополнительные электронные поощрения
          в виде скидок, бонусов и купонов у программы лояльности «Клуб Друзей»,
          промокодов интернет-магазинов “SPAR Online” и “SPAR39минут”,
          промокодов “МТС Premium”, а также развлекательный контент
          «Предсказание от игры». <br />
          <br />
          Призовой фонд состоит из: <br />
          1. Призов для еженедельных розыгрышей (Призы первого уровня): <br />
          - наименование приза: <br />
          Apple iPhone 16 256 GB; <br />
          - общее количество призов в Акции – 16 ед.; <br />
          - стоимость материальной части приза: 96 990 (девяносто шесть тысяч
          девятьсот девяносто) рублей 00 копеек; <br />- стоимость денежной
          части приза: 50 072 (пятьдесят тысяч семьдесят два) рубля 00 копеек
          для лиц, которые предоставят заявление о том, что в течение налогового
          периода не пользовались освобождением от обложения НДФЛ 4 000 рублей
          по п. 28 ст. 217 НК РФ или 52 225 (пятьдесят две тысячи двести
          двадцать пять) рублей 00 копеек для лиц, которые ранее в течение
          данного налогового периода использовали право на освобождение от
          обложения НДФЛ 4 000 рублей по п. 28 ст. 217 НК РФ. <br />
          <br />
          2. Примерный перечень дополнительных поощрений, которые могут выпасть
          случайным образом в электронных монетах (Призы второго уровня): <br />
          - Предсказание от игры. <br />- Скидка 100 % на 1 шт. товара при
          покупке в магазинах SPAR и «Семья». <br />
          - Скидки 25-30 % на определённые категории товаров в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Купон на 300 бонусов программы лояльности «Клуб Друзей» при покупке
          на сумму от 1000 рублей в магазинах SPAR, «Семья» и «Победа». <br />
          - Промокод SPAR ONLINE - скидка 200 рублей на заказ и бесплатная
          доставка <br />- Промокод SPAR ONLINE - скидка 500 рублей на заказ от
          3000 руб. <br />
          - Промокод SРAR ONLINE - скидка 300 рублей на заказ <br />
          - Промокод на бесплатный период пользования подпиской “МТС Premium”.
          Промокод на МТС Premium даёт возможность подключить подписку со
          скидкой 100% на срок 30 дней. Предложение действительно только для
          новых пользователей подписки. Промокод может быть активирован
          однократно. По истечении 30 дневного периода подписка МТС Premium
          продлится автоматически, плата за нее составит 249 рублей в месяц.
          Промокод необходимо активировать в период с 01.10.24 по 30.11.24 на
          сайте https://premium.mts.ru/. Предложение действительно для абонентов
          любых операторов мобильной связи. Подробнее с условиями подписки можно
          ознакомиться на сайте https://premium.mts.ru/. <br />
          - и другие <br />
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.2. Указанная стоимость Призов первого уровня является
          ориентировочной, окончательная стоимость будет указана в документах,
          подписываемых с Победителями в период выдачи Призов.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.3. Организатор оставляет за собой право до даты окончания Акции
          вносить изменения в указанный в настоящем пункте перечень Призов, в
          т.ч. производить их замену на Призы иной стоимости и комплектации.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.4. Внешний вид, комплектация оригинального Приза первого уровня
          может отличаться от его изображения в рекламных материалах.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.5. Факт участия в Акции Участника (п. 4.3. настоящих Правил)
          означает полное согласие Участника с настоящими Правилами. Участник
          Акции, присоединяясь к настоящим Правилам, подтверждает, что
          ознакомлен и согласен с тем, что Организатор Акции выступает налоговым
          агентом по отношению к Участнику и, получившему призы, стоимостью выше
          4 000 (четырех тысяч) рублей (п. 3.1. настоящих Правил) и, как
          налоговый агент, удержит из Денежной части приза, указанной в п. 3.1.
          настоящих Правил сумму НДФЛ, превышающую 50 (пятьдесят) процентов от
          суммы Денежной части приза.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          4. УСЛОВИЯ АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.1. Участники Акции имеют, в частности, следующие права: <br />
          - право на получение информации об Акции в соответствии с настоящими
          Правилами; <br />
          - право на получение Призов Акции в случае, если Участник будет
          признан выигравшим, в соответствии с настоящими Правилами; <br />-
          иные права, предусмотренные настоящими Правилами и действующим
          законодательством Российской Федерации.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.2. Участники Акции несут, в частности, следующие обязанности: <br />
          - соблюдать Правила Акции во время ее проведения; <br />
          - предоставлять Организатору достоверную информацию о себе в
          соответствии с Правилами Акции; <br />- иные обязанности,
          предусмотренные настоящими Правилами и действующим законодательством
          Российской Федерации.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.3. Чтобы принять участие в Акции и получить приз, нужно: <br />
          - “SPAR”, “EUROSPAR”, “INTERSPAR”, “SPAR Express”, “SPAR Gourmet”,
          «Семья» и «Победа» Калининградской области, а также интернет-магазинов
          “SPAR Online” и “SPAR39минут” с использованием сервиса “KD Pay” в
          качестве платёжного метода с 10:00 14 октября 2024 года до 23 часов 59
          минут 00 секунд 17 ноября 2024 года (по местному времени), при этом
          сумма оплаты с использованием сервиса “KD Pay” должна составлять не
          менее 500 рублей. <br />- Воспользоваться полученной электронной
          монетой для открытия электронного подарочного бокса и получения приза
          в мобильном приложении «Клуб Друзей».
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.4. Электронные монеты для открытия электронного подарочного бокса и
          получения Приза выдаются следующим образом: за покупку с
          использованием сервиса “KD Pay” в качестве платёжного метода (сумма
          оплаты с использованием сервиса “KD Pay” должна составлять не менее
          500 рублей) Участник Акции получает 1 электронную монету в мобильном
          приложении «Клуб Друзей». <br />
          <br />
          Электронная монета выдается в расчете от итоговой суммы Чека, после
          учета всех скидок.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.5. Для участия в розыгрыше Призов первого уровня необходимо получить
          «Золотой билет» после открытия подарка электронной монетой в Разделе
          «Осень подарков». При получении «Золотого билета» Участнику Акции
          автоматически присваивается уникальный код для еженедельного розыгрыша
          Призов первого уровня.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.6. При смене номера телефона, с помощью которого Участник Акции был
          зарегистрирован в платёжном сервисе “KD Pay”, все выпущенные ранее для
          этого номера «Золотые билеты» и другие призы сгорают и не подлежат
          восстановлению.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.7. В случае обнаружения Организатором Акции подозрительной
          активности, связанной с действиями определённых Участниками Акции,
          Организатор Акции оставляет за собой право удаления всех уникальных
          кодов таких Участников из общей базы данных уникальных кодов для
          еженедельного розыгрыша Призов первого уровня, без уведомления об этом
          данных Участников Акции.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          5. ПОРЯДОК ОПРЕДЕЛЕНИЯ ПОБЕДИТЕЛЯ АКЦИИ И ВЫДАЧИ ПРИЗОВ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.1. Для определения Победителей Акции Организатор создает экспертную
          комиссию, состоящую из трех человек. Члены комиссии – сотрудники ООО
          «РИТЕЙЛ-ПРОЦЕССИНГ», граждане РФ, совершеннолетние, дееспособные и
          правоспособные. <br />
          - Один представитель от Организатора Акции для контроля за проведением
          процедуры проведения еженедельного розыгрыша Призов первого уровня и
          соответствия ее Правилам. <br />
          - Один представитель от Организаторов Акции для предоставления реестра
          всех зарегистрированных уникальных кодов для проведения еженедельного
          розыгрыша Призов первого уровня. <br />- Один председатель комиссии,
          представитель Организатора Акции, для общего контроля за
          вышеперечисленными процедурами.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.2. Определение Победителей Акции с проведением еженедельных
          розыгрышей Призов первого уровня происходит по следующему графику:{' '}
          <br />
          <br />
          1. период выдачи золотых билетов с 10 часов 00 минут 14 октября по 20
          октября 2024 года до 23 часов 59 минут – дата и время определения
          победителей – 19:00, 23 октября 2024 года; <br />
          2. период выдачи золотых билетов с 00 часов 00 минут 21 октября по 27
          октября 2024 года до 23 часов 59 минут – дата и время определения
          победителей – 19:00, 30 октября 2024 года; <br />
          3. период выдачи золотых билетов с 00 часов 00 минут 28 октября по 03
          ноября 2024 года до 23 часов 59 минут – дата и время определения
          победителей – 19:00, 6 ноября 2024 года; <br />
          4. период выдачи золотых билетов с 00 часов 00 минут 04 ноября по 10
          ноября 2024 года до 23 часов 59 минут – дата и время определения
          победителей – 19:00, 13 ноября 2024 года; <br />
          5. период выдачи золотых билетов с 00 часов 00 минут 11 ноября по 17
          ноября 2024 года до 23 часов 59 минут – дата и время определения
          победителей – 19:00, 20 ноября 2024 года. <br />
          <br />
          В розыгрышах принимают участие «Золотые билеты» с уникальными кодами,
          полученные в указанные периоды. Не допускается к участию в розыгрыше
          коды, полученные ранее или позднее указанного периода. <br />
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.3. Определение Победителей будет производиться путем случайного
          выпадения номеров победителей с помощью Сервиса генератора случайных
          чисел{' '}
          <a href="https://randomus.ru/" target="_blank" rel="noreferrer">
            https://randomus.ru/
          </a>
          , используемого для случайного выбора единичных данных из баз данных и
          прочих массивов информации в едином регистре.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.4. Участник, выигравший один приз Первого уровня в текущем
          розыгрыше, не может претендовать на такой же приз в данном и
          последующих розыгрышах. Все «Золотые билеты» с уникальными кодами, не
          выигравшие в текущем розыгрыше, аннулируются и не принимают участие в
          следующем.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.5. Призы Первого уровня не вручаются Победителям по следующим
          причинам: <br />
          - организатор не смог связаться с Участником и уведомить его о победе
          в прямом эфире розыгрыша. В таком случае победитель выбирается заново
          в рамках текущего прямого эфира; <br />
          - победитель отказался от приза или подписания Акта приемки-передачи
          или иных документов, связанных с вручением призов; <br />
          - победитель не смог приехать в центр выдачи призов в течении двух
          суток с момента определения победителей розыгрыша или не смог прислать
          своих делегатов с доверенностью на получение приза; <br />
          - победитель не выполнил какие-либо действия, необходимые для
          получения Приза и предусмотренные настоящим Положением либо совершил
          их с нарушением установленного срока; <br />
          - при проверке документов установлено, что Участник представил о себе
          неполную, искаженную или недостоверную информацию; <br />
          <br />
          Организатор Акции не несет ответственности за правильность заполнения
          Участником его контактных данных при регистрации в программе
          лояльности «Клуб Друзей». <br />
          <br />
          Организатор Акции вправе отказать в участии любому лицу без объяснения
          причин, если есть основания полагать, что такой Участник совершил
          неправомерные действия, которые повлияли на результат Акции или
          нарушил иные условия данного Положения.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.6. Организатор Акции не несет ответственности в случае, если
          Победитель не явится в указанное время и дату для получения приза. В
          таком случае приз не вручается и остается у Организатора Акции и
          используется Организатором Акции по своему усмотрению.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.7. Итоги еженедельных розыгрышей будут опубликованы на официальном
          аккаунте Организатора «SPAR Калининград» в социальной сети «Вконтакте»
          (
          <a href="https://vk.com/spar_kd" target="_blank" rel="noreferrer">
            https://vk.com/spar_kd
          </a>
          ) в течение 24-часов со дня проведения мероприятия по определению
          победителей.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.8. В момент проведения Мероприятия по определению победителей
          (прямого эфира на официальном аккаунте Партнёра Акции ООО «Евроритейл»
          в социальной сети «ВКонтакте» (https://vk.com/spar_kd)), согласно
          графику розыгрышей, указанному в п. 5.2. настоящих Правил, Организатор
          Акции связывается с Победителями первой категории призов по номеру
          телефона, указанному при регистрации платёжном сервисе “KD Pay” и
          сообщает ему о его выигрыше. Если Победитель не выходит на связь после
          трёх звонков в период проведения прямого эфира, либо находится вне
          зоны доступа, такой победитель считается не вышедшим на связь и его
          приз переразыгрывается в рамках текущего розыгрыша.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.9. Ответственность Организатора Акции и Партнёра Акции по выдаче
          Призов первого уровня ограничена исключительно указанными в п. 3.1.
          настоящих Правил количеством и характеристиками. Все претензии
          относительно качества Призов первого уровня необходимо предъявлять
          непосредственно товаропроизводителю.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.10. Договор между Организатором Акции и Участником Акции является
          безвозмездным, т.е. потенциальный Участник не вносит отдельной платы
          за участие в ней.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.11. Факт открытия электронной монетой электронного подарочного бокса
          в Разделе «Осень подарков» в мобильном приложении «Клуб Друзей»
          подразумевает, что данный Участник Акции ознакомлен и полностью
          согласен с настоящими Правилами, согласен на сбор обработку, хранение
          Организатором Акции и уполномоченными им лицами персональных данных
          Участника Акции, согласен на рассылку SMS-рекламы, рекламы данной
          Акции, а также любой информации касающейся Акции. Кроме того, факт
          открытия электронной монетой электронного подарочного бокса в Разделе
          «Осень подарков» в мобильном приложении «Клуб Друзей» подтверждает
          согласие участника Акции в случае победы в одном из розыгрышей на
          трансляцию в прямом эфире аудиосигнала телефонного звонка, на
          указанный при регистрации в платёжном сервисе “KD Pay” номер
          мобильного телефона, представителя организатора Акции с целью
          информирования Участника о выпадении его номера, в социальных сетях
          Организатора Акции и его Партнёров.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.12. Обязанности по исчислению и уплате налогов, связанных с
          получением Призов первого уровня, а также ответственность за
          неисполнение этой обязанности, как налоговый агент по отношению к
          Участнику Акции несет Организатор Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.13. Приз первого уровня вручается в следующем порядке: <br />
          Победителям, в течение 1-го рабочего дня с даты оповещения необходимо
          предоставить по адресу г. Калининград, Советский проспект, зд. 234,
          помещение 4 следующую информацию о себе: <br />
          - ФИО; <br />
          - почтовый адрес; <br />
          - копию страниц своего паспорта РФ (разворот с фотографией, страница с
          информацией о действующей регистрации); <br />
          - копию свидетельства ИНН; <br />
          - копию СНИЛС; <br />- иную информация по запросу Организатора;
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.14. После получения от Участника информации, указанной в п. 5.14.
          Положения, Организатор согласовывает дату, время и место вручения
          Приза.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.15. Победитель обязан подписать Акт приемки-передачи приза и иные
          документы, связанные с передачей ему приза.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          6. ПРАВА И ОБЯЗАННОСТИ УЧАСТНИКОВ АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.1. Участник Акции вправе требовать от Организатора Акции: <br />-
          получения информации об Акции в соответствии с правилами Акции; <br />
          - при соблюдении всех условий данного Положения, выдачи приза.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.2. Организатор Акции настоящим Положением информируют Участника о
          том, что в соответствии с положениями Налогового Кодекса РФ стоимость
          призов, полученных от организаций, превышающая 4 000 руб. за отчетный
          период (календарный год), в том числе полученных в натуральной форме,
          включается в налоговую базу по НДФЛ. Принимая участие в Акции и
          соглашаясь с настоящими правилами, Участники, в том числе Победители,
          считаются надлежащим образом проинформированными о вышеуказанной норме
          налогового законодательства РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.3. Участник Акции обязуется указывать точные, актуальные и
          достоверные данные при регистрации в программе лояльности «Клуб
          Друзей» и в платёжном сервисе “KD Pay” согласно п. 4.2 настоящих
          Правил.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.4. Принимая приз, покупатель даёт согласие на проведение фото- и
          видеосъемки с его участием в момент передачи приза и публикацию фото-
          и видеоматериалов с его изображением в социальных сетях и других
          рекламных материалах Организатора Акции и Партнёров Акции.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          7. ПРАВА, ОБЯЗАННОСТИ И ОТВЕТСТВЕННОСТЬ ОРГАНИЗАТОРА
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.1. Организатор Акции обязуется провести Акцию при технической
          поддержке, осуществляемой мобильным приложением Клуба Друзей; а также
          обеспечить получение участниками Призов в соответствии с условиями
          Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.2. Организатор Акции не несет ответственность за неисполнение либо
          ненадлежащие исполнение своих обязательств, а также за какие-либо
          прямые, косвенные, особые потери Участников, связанные с участием в
          данной Акции, если неисполнение обязательств явилось следствием
          непредвидимых обстоятельств непреодолимой силы. Организатор не обязан
          возмещать потери участникам Акции в подобных случаях.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.3. Организатор Акции имеет право отстранить Участника от участия в
          Акции на любом этапе проведения Акции, если возникли подозрения, что
          Участник (или кто-то другой за него) в ходе Акции нарушает условия
          акции или пытается изменить ее результаты посредством технических,
          программных или других средств, кроме способов, описанных в правилах
          проведения Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.4. Организатор Акции не несет ответственности за любой ущерб,
          который возможно понесет Победитель вследствие использования им Призов
          и/или участия в Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.5. Организатор Акции имеет право отказать Победителю в
          предоставление Призов, если Победитель предоставил о себе неверную
          информацию, предоставил ее несвоевременно или каким-либо другим
          образом нарушил настоящие Правила. В случае проверки Победителя или
          Участника Акции на соблюдения условий акции Организатор вправе
          потребовать от Победителя или Участника Акции доказательства
          совершения покупок в магазинах Участниках Акции (выписки с банковских
          счетов, копии чеков и т.д.). Если Победитель или Участник Акции не
          может доказать факт покупки (соблюдения условий Акции) Организатор
          Акции вправе отказать в получении приза и переиграть приз, а также
          признать факт нарушения Участником условий Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.6. Организатор Акции не несет ответственности за непредоставление
          Участником Акции необходимых сведений для получения Приза, в том числе
          по вине почтовой службы, организации связи. За технические проблемы
          и/или мошенничества в сети Интернет и/или каналов связи, используемых
          при проведении Акции, а также за невозможность осуществления связи с
          Участником Акции из-за указанных неверных или неактуальных контактных
          данных, в том числе номера телефона, ФИО участника и иных данных, а
          также технических проблем, возникших в момент регистрации в программе
          лояльности «Клуб Друзей», платёжном сервисе “KD Pay”, процессинге
          фискального чека и обработки платёжной транзакции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.7. Организатор Акции не несет ответственность за технические
          неполадки в мобильном приложении «Клуб Друзей», в результате которых
          может возникнуть сбой в его работе.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.8. Организатор Акции оставляет за собой право не вступать в
          письменные переговоры либо иные контакты с Участниками Акции, кроме
          случаев, предусмотренных настоящими Правилами и действующим
          законодательством РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.9. Организатор Акции имеет право на свое усмотрение в одностороннем
          порядке прекратить проведение Акции или изменить её условия, если по
          какой-то причине любой аспект настоящей Акции не может проводиться
          так, как это запланировано.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.10. Настоящие Правила является единственными официальными правилами
          участия в Акции. В случае возникновения ситуаций, допускающих
          неоднозначное толкование данных правил, окончательное решение о таком
          толковании принимается исключительно Организатором Акции в
          соответствии с законодательством РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.11. Организатор имеет право осуществить фото- и видеосъёмку
          Победителя Акции с Призом первого уровня и разместить эту информацию в
          СМИ, на сайте программы лояльности «Клуб Друзей»{' '}
          <a href="https://friendsclub.ru" target="_blank" rel="noreferrer">
            friendsclub.ru
          </a>{' '}
          и в мобильном приложении «Клуб Друзей», а также во всех социальных
          сетях Организатора Акции и Партнёров.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          8. НАЛОГОВЫЕ ОБЯЗАТЕЛЬСТВА
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.1. Победители Акции обязаны осуществлять уплату всех налогов и иных
          существующих обязательных платежей, связанных с получением Призов в
          соответствии с действующим законодательством Российской Федерации.
          Согласно действующему законодательству Российской Федерации, не
          облагаются налогом на доходы физических лиц (НДФЛ) доходы, не
          превышающие в совокупности 4 000 (Четырех тысяч) рублей, полученные за
          налоговый период (календарный год) от организаций, в том числе в виде
          подарков, выигрышей или призов в проводимых конкурсах, акциях, играх и
          других мероприятиях в целях рекламы товаров, работ, услуг (п.28 ст.217
          Налогового кодекса Российской Федерации (НК РФ)).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.2. Принимая участие в Акции и соглашаясь с настоящими Правилами,
          Участники Акции считаются надлежащим образом проинформированными об
          обязанностях уплаты налога на доходы физических лиц (НДФЛ) со
          стоимости Приза(ов), превышающей 4 000 (Четыре тысячи) рублей, по
          ставке 35% (тридцать пять процентов) на основании п.1 ст.207, п.1
          ст.210, п.2 ст.224 НК РФ, а также об обязанностях налогового агента
          (Организатора) удержать начисленную сумму НДФЛ непосредственно из
          доходов Участника Акции (Получателя дохода) при их фактической выплате
          на основании п.п. 4 и 5 ст.226 НК РФ для перечисления в бюджет
          соответствующего уровня.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.3. В случае, если стоимость вручаемых в рамках Акции Призов не
          превышает 4 000 (четырех тысяч) рублей, Организатор не выполняет
          обязанностей налогового агента по удержанию НДФЛ.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.4. В случае, если Участник Акции не предоставил Организатору
          Поручение об удержании и уплате 100 % начисленного НДФЛ из денежной
          составляющей Приза, имеющего совокупную стоимость свыше 4000 (четыре
          тысячи) рублей, Организатор, выполняющий функции налогового агента,
          удерживает и уплачивает НДФЛ из денежной составляющей Приза только в
          части, предусмотренной абз. 2 п. 4 ст. 226 НК РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.5. Организатор настоящим информирует Участников Акции о
          законодательно предусмотренной обязанности самостоятельно уплатить
          соответствующие налоги с момента получения от организаций подарков,
          выигрышей или призов в проводимых конкурсах, акциях, играх и других
          мероприятиях в целях рекламы товаров (работ, услуг), совокупная
          стоимость которых превысит 4 000 (четыре тысячи) рублей за отчетный
          период (календарный год).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.6. Приз включает в себя материальную и денежную часть приза,
          Организатор рассчитывает НДФЛ и удерживает его из причитающейся
          денежной части приза в соответствии с действующим налоговым
          законодательством Российской Федерации для перечисления его в бюджет
          соответствующего уровня. При этом Победитель соглашается, что
          Организатор может направить до 100% от денежной части приза на уплату
          налога.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.7. Организатор по поручению обладателя приза, в соответствии со ст.
          226 НК РФ удерживает и перечисляет в бюджет НДФЛ на приз по ставке 35%
          от общей стоимости приза, превышающей сумму 4 000 руб. Победитель
          согласен на удержание и перечисление НДФЛ в полном размере, без учета
          ограничения, предусмотренного абз. 2 ч. 4 ст. 226 НК РФ, по итогам
          чего погашается задолженность Победителя перед бюджетом по уплате НДФЛ
          на приз в полном объеме. Никаких иных налоговых обязанностей в связи с
          получением приза по настоящей Акции у Победителя не возникает.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          9. ПЕРСОНАЛЬНЫЕ ДАННЫЕ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          9.1. Принимая участие в Акции и добровольно предоставляя свои
          персональные данные, Участник подтверждает свое согласие на обработку
          Организатором Акции предоставленных данных, включая сбор, запись,
          систематизацию, накопление, использование, хранение, уточнение,
          обновление, изменение, извлечение, использование, передачу
          распространение, обезличивание, блокирование, удаление, уничтожение
          для целей проведения Акции на весь срок ее проведения, в соответствии
          с положениями предусмотренными Федеральным законом РФ № 152-ФЗ от 27
          июля 2006г. «О персональных данных (далее – Закон).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          9.2. Участники понимают и соглашаются с тем, что персональные данные,
          указанные/предоставленные ими для участия в Акции, будут
          обрабатываться всеми необходимыми способами в целях проведения Акции,
          иными целями в соответствии с настоящим Положением, и дают свое
          согласие на такую обработку.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          9.3. Принимая участие в Акции, Участники подтверждают свое согласие
          тем, что Организатор и уполномоченные им лица, которые будут соблюдать
          необходимые меры защиты таких данных от несанкционированного
          распространения, вправе осуществлять сбор, систематизацию, хранение, а
          также иным способом обрабатывать персональные данные Участника,
          который Участник предоставляет по запросу Организатора Акции-данные
          паспорта гражданина РФ: <br />
          - ФИО; <br />
          - дата и место рождения; <br />
          - серия и номер паспорта, дата выдачи, наименование выдавшего органа,
          код подразделения; <br />
          - адрес регистрации по месту жительства; <br />
          - номер ИНН. <br />
          Вышеперечисленные персональные данные получаются и обрабатываются
          Организатором исключительно в целях, проведения настоящей Акции, для
          выдачи приза в случае выигрыша. <br />
          <br />
          Согласие действительно с момента регистрации уникального кода в
          мобильном приложении «Клуб Друзей». <br />
          <br />
          Персональные данные Участника хранятся в базе Организатора Акции в
          течение срока проведения Акции и в течение 4 (двух) лет после её
          окончания. Субъект персональных данных вправе отозвать свое согласие,
          отправив электронное письмо Организатору.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          10. ПРОЧЕЕ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          10.1. Любое время, указанное в настоящих Правилах, считается
          по-местному Калининградскому времени.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          10.2. Во всех ситуациях, не предусмотренным настоящими Правилами,
          Организатор Акции и Участники Акции руководствуются действующим
          законодательством РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          10.3. Стороны освобождаются от ответственности за невыполнение или
          ненадлежащие выполнение своих обязательств, если такое невыполнение
          явилось результатом действия непреодолимой силы.
        </Text>
      </LayoutMobile.Content>
    </LayoutMobile>
  </GlobalStyle>
)
