export const RAFFLES_REG_TERM = [
  {
    from: '2024-10-14 10:00',
    to: '2024-10-20 23:59',
    raffle: { day: 23, month: 'октября' },
  },
  {
    from: '2024-10-21 00:00',
    to: '2024-10-27 23:59',
    raffle: { day: 30, month: 'октября' },
  },
  {
    from: '2024-10-28 00:00',
    to: '2024-11-03 23:59',
    raffle: { day: 6, month: 'ноября' },
  },
  {
    from: '2024-11-04 00:00',
    to: '2024-11-10 23:59',
    raffle: { day: 13, month: 'ноября' },
  },
  {
    from: '2024-11-11 00:00',
    to: '2024-11-17 23:59',
    raffle: { day: 20, month: 'ноября' },
  },
]
