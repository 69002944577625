import { Box, Button } from 'ui/atoms'
import { PopupGameStartingDateBase } from 'ui/molecules'
import { buttonProps } from './buttonProps'
import { headingTextProps } from './headingTextProps'
import { congratulationsTextProps } from './congratulationsTextProps'
import { config } from 'config'

export const PopupGameStartingDate = ({ isOpened, onClose }) => (
  <PopupGameStartingDateBase isOpened={isOpened} onClose={onClose}>
    <Box {...headingTextProps}>
      {config.texts.popups.gameStartingDate.heading.map((textLine, index) => {
        return <div key={index}>{textLine}</div>
      })}
    </Box>
    {config.texts.popups.gameStartingDate.subheading &&
      config.texts.popups.gameStartingDate.subheading.length > 0 && (
        <Box {...congratulationsTextProps}>
          {config.texts.popups.gameStartingDate.subheading.map(
            (textLine, index) => {
              return <div key={index}>{textLine}</div>
            }
          )}
        </Box>
      )}
    <Button {...buttonProps} onClick={onClose}>
      {config.texts.popups.gameStartingDate.button}
    </Button>
  </PopupGameStartingDateBase>
)
